<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='分类名称' prop='name'>
          <a-input v-model='form.name' />
        </a-form-model-item>
        <a-form-model-item label='分类状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio value='online'>启用</a-radio>
            <a-radio value='offline'>停用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='排序优先级' prop='priority' help='数字越大优先级又高'>
          <a-input-number v-model='form.priority' />
        </a-form-model-item>
        <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
          <a-button @click='handleReset'>
            重置
          </a-button>
          <a-divider type='vertical' />
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  getProductionCategory,
  putProductionCategory
} from '@/api/production'


export default {
  name: 'ProductionCategoryEdit',
  data() {
    return {
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      form: {
        id: '',
        name: '',
        status: '',
        deleted: 0,
        priority: 0
      },
      rules: {
        name: [
          { required: true, message: '请填写分类名称', trigger: 'change' },
          { min: 4, max: 6, message: '分类名称字符数限定4～6个', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择分类状态', trigger: 'change' }
        ],
        priority: [{ required: true, message: '请填写排序优先级', trigger: 'change' }]
      }
    }
  },
  created() {
    this.handleInitial()
    this.$form.createForm(this)
  },
  activated() {
    this.handleInitial()
    this.$form.createForm(this)
    // debugger;
  },
  methods: {
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getProductionCategory(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
        })
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          putProductionCategory(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
